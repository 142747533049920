/* unplugin-vue-components disabled */import __unplugin_components_6 from 'C:/docker/118_meijia/frontend/src/components/CardTableList.vue';
import __unplugin_components_5 from 'C:/docker/118_meijia/frontend/src/components/includes/NoData.vue';
import __unplugin_components_4 from 'C:/docker/118_meijia/frontend/src/components/BaseSpinner.vue';
import __unplugin_components_3 from 'C:/docker/118_meijia/frontend/src/components/BRow.vue';
import __unplugin_components_2 from 'C:/docker/118_meijia/frontend/src/components/BaseButton.vue';
import __unplugin_components_1 from 'C:/docker/118_meijia/frontend/src/components/form/MInput.vue';
import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/MDateRange.vue';
import _objectSpread from "C:\\docker\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "p-course-record"
};
var _hoisted_2 = {
  class: "col-auto"
};
var _hoisted_3 = {
  class: "col-auto"
};
var _hoisted_4 = {
  class: "col-auto"
};
var _hoisted_5 = {
  class: "col-auto"
};
import { Pos } from "@/js/services/baseService";
import { numberFormat } from '@/js/functions';
import moment from 'moment';
export default {
  __name: 'CourseRecord',
  setup: function setup(__props) {
    var router = useRouter();
    var store = useStore();

    var query = _.defaults(router.currentRoute.value.query, {
      id: ''
    });

    var searchForm = {
      dateFrom: '',
      dateTo: '',
      keyword: ''
    };
    var cData = reactive(_objectSpread(_objectSpread({
      ajaxDone: false,
      recordPanelShow: false
    }, searchForm), {}, {
      cols: [{
        name: '服務日期',
        slot: 'date',
        width: 221 / 1490 * 100
      }, {
        name: '課程碼',
        slot: 'course-number',
        width: 190 / 1490 * 100
      }, {
        name: '課程名稱',
        slot: 'course-name',
        width: 416 / 1490 * 100
      }, {
        name: '使用堂數',
        slot: 'course-amount',
        width: 199 / 1490 * 100
      }, {
        name: '距離天數',
        slot: 'days-left',
        width: 145 / 1490 * 100
      }, {
        name: '服務人員',
        slot: 'server',
        width: 324 / 1490 * 100
      }],
      list: []
    }));

    function showPanel() {
      cData.recordPanelShow = true;
    }

    function clearForm() {
      Object.assign(cData, searchForm);
    }

    var getList = function getList() {
      var startAt = '';

      if (cData.dateFrom) {
        startAt = moment(cData.dateFrom).format('YYYY/MM/DD');
      }

      var endAt = '';

      if (cData.dateTo) {
        endAt = moment(cData.dateTo).format('YYYY/MM/DD');
      }

      Pos.handle('GetCustClass', {
        CSID: query.id,
        keyWord: cData.keyword,
        begDate: startAt,
        endDate: endAt
      }).then(function (response) {
        cData.list = response.data;
      }).finally(function () {
        cData.ajaxDone = true;
      });
    };

    var refreshList = _.debounce(getList, 300);

    watch(function () {
      return [cData.keyword, cData.dateFrom, cData.dateTo];
    }, function () {
      return refreshList();
    });
    getList();
    return function (_ctx, _cache) {
      var _component_MDateRange = __unplugin_components_0;

      var _component_MInput = __unplugin_components_1;

      var _component_BaseButton = __unplugin_components_2;

      var _component_BRow = __unplugin_components_3;

      var _component_BaseSpinner = __unplugin_components_4;

      var _component_NoData = __unplugin_components_5;

      var _component_CardTableList = __unplugin_components_6;

      var _directive_width = _resolveDirective("width");

      var _directive_fill_height_fixed = _resolveDirective("fill-height-fixed");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BRow, {
        mb: "",
        "gutter-x": "5px"
      }, {
        default: _withCtx(function () {
          return [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_MDateRange, {
            start: _unref(cData).dateFrom,
            "onUpdate:start": _cache[0] || (_cache[0] = function ($event) {
              return _unref(cData).dateFrom = $event;
            }),
            end: _unref(cData).dateTo,
            "onUpdate:end": _cache[1] || (_cache[1] = function ($event) {
              return _unref(cData).dateTo = $event;
            })
          }, null, 8, ["start", "end"])])), [[_directive_width, 260]]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_MInput, {
            modelValue: _unref(cData).keyword,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
              return _unref(cData).keyword = $event;
            }),
            placeholder: "請輸入關鍵字",
            icon: "icon-search"
          }, null, 8, ["modelValue"])])), [[_directive_width, 175]]), _createElementVNode("div", _hoisted_4, [_withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
            title: "送出",
            variant: "theme",
            h: "40px",
            radius: "4px",
            fz: "16px"
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(" 送出 ")];
            }),
            _: 1
          })), [[_directive_width, 54]])]), _createElementVNode("div", _hoisted_5, [_withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
            title: "清除",
            variant: "gray",
            h: "40px",
            radius: "4px",
            fz: "16px",
            onClick: _cache[3] || (_cache[3] = function ($event) {
              return clearForm();
            })
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(" 清除 ")];
            }),
            _: 1
          })), [[_directive_width, 54]])])];
        }),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_CardTableList, {
        class: "book-list",
        cols: _unref(cData).cols,
        list: _unref(cData).list
      }, {
        header: _withCtx(function (slotProp) {
          return [_createTextVNode(_toDisplayString(slotProp.item.name), 1)];
        }),
        date: _withCtx(function (slotProp) {
          return [_createTextVNode(_toDisplayString(slotProp.item.srvDate) + " ", 1), _createElementVNode("div", null, _toDisplayString(slotProp.item.time), 1)];
        }),
        "course-number": _withCtx(function (slotProp) {
          return [_createTextVNode(_toDisplayString(slotProp.item.classItemCode), 1)];
        }),
        "course-name": _withCtx(function (slotProp) {
          return [_createTextVNode(_toDisplayString(slotProp.item.itemName), 1)];
        }),
        "course-amount": _withCtx(function (slotProp) {
          return [_createTextVNode(_toDisplayString(_unref(numberFormat)(slotProp.item.classQty)), 1)];
        }),
        "days-left": _withCtx(function (slotProp) {
          return [_createTextVNode(_toDisplayString(_unref(numberFormat)(slotProp.item.daysCount)), 1)];
        }),
        server: _withCtx(function (slotProp) {
          return [_createTextVNode(_toDisplayString(slotProp.item.stfFeatX), 1)];
        }),
        "content-end": _withCtx(function () {
          return [!_unref(cData).ajaxDone ? (_openBlock(), _createBlock(_component_BaseSpinner, {
            key: 0,
            "block-center": ""
          })) : _createCommentVNode("", true), _unref(cData).ajaxDone ? (_openBlock(), _createBlock(_component_NoData, {
            key: 1,
            variant: "logo",
            watch: "",
            "watch-data": _unref(cData).list
          }, null, 8, ["watch-data"])) : _createCommentVNode("", true)];
        }),
        _: 1
      }, 8, ["cols", "list"])), [[_directive_fill_height_fixed, {
        minus: 65
      }]])]);
    };
  }
};